<template>
  <div>
    <a-modal v-model:open="open" title="Add Classification" @ok="classify">
      <hr />
      <a-form
        ref="validationForm"
        :model="formState"
        name="basic"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
      >
        <div class="columns">
          <div class="column">
            <span class="is-size-5">Highlighted Text</span>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <span class="has-background-white-ter px-2 pb-1">{{
              highlightedAttributes.Phrase
            }}</span>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column">
            <a-form-item
              name="classificationContext"
              :rules="[{ required: true, message: 'Please select a context!' }]"
            >
              <div class="is-size-6 mb-1">{{ $t('Classify as:') }}</div>
              <input-autocomplete-dropdown
                v-model:value="formState.classificationContext"
                data-cy="add-validation-dropdown"
                :data-source="contextOptions[0].options"
              />
            </a-form-item>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import useValidationStore from '@/store/modules/validation'
import { ref, reactive, watch } from 'vue'
import useAbility from '@/services/ability.js'
import InputAutocompleteDropdown from '@/components/inputs/input-autocomplete-dropdown.vue'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import useConfigStore from '@/store/modules/config'
import { find } from 'lodash'

const validationStore = useValidationStore()
const documentNLPMixin = useDocumentNLPMixin()
const configStore = useConfigStore()
const webConfig = configStore.getConfigByKey('web-config')

const ability = useAbility()
const contextOptions = ref([])
const formState = reactive({})
const validationForm = ref(null)
const open = ref(false)

const props = defineProps({
  document: {
    type: Object,
    required: true,
  },
  highlightedAttributes: {
    type: Object,
    required: false,
  },
})

watch(
  () => open.value,
  (value) => {
    documentNLPMixin.setMainScrolling(value)
  },
)

contextOptions.value = validationStore.getContextOptions(
  'classification',
  ability.can('viewAll', 'context:validation'),
)

const classify = async () => {
  try {
    await validationForm.value.validate()
  } catch (error) {
    console.log('validation failed')
    return
  }

  validationStore.createValidation({
    FileId: props.document.uuid,
    FileNumber: props.document.FileNumber,
    RovaVersion: props.document.RovaVersion,
    Classifier: find(webConfig.documents.classifiers, {
      longName: formState.classificationContext,
    }).rovaClassifier,
    ...props.highlightedAttributes,
  })

  open.value = false
}

defineExpose({
  openModal: () => {
    open.value = true
  },
})
</script>
