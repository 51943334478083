<template>
  <div
    class="vis-timeline-wrapper pb-4"
    :class="{ 'vis-timeline-fullscreen': uiStore.state.isFullscreen }"
  >
    <div id="visualization" />
    <n-date-slider
      v-if="rangeOptions.min && rangeOptions.max"
      type="double"
      :clip="true"
      :drag-interval="true"
      :force-edges="false"
      :prettify="getFriendlyUNIXTime"
      :options="rangeOptions"
      @change="updateVisVisibleWindow"
    />
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import dayjs from 'dayjs'
import config from '@/config'
import { DataSet, Timeline } from 'vis-timeline/standalone'
import useVisTooltipMixin from '@/components/timeline/vis-timeline/timeline-tooltips.js'
import NDateSlider from '@/components/ui/n-date-slider/n-date-slider.vue'
import useTimelineStore from '@/store/modules/timeline'
import useAgGridStore from '@/store/modules/agGrid'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import { onMounted, watch, ref, computed, toRef } from 'vue'
import { getFriendlyUNIXTime } from '@/mixins/n-time.js'
import useActivityStore from '@/store/modules/activity'
import useUiStore from '@/store/modules/ui'
import visTimelineOptions from '@/config/timeline/vis-timeline-options.js'
import useAbility from '@/services/ability.js'
import { nextTick } from 'process'
import { daysBetweenDates, getDateFromSSQL } from '@/mixins/n-time'
import useTimelineMixin from '@/mixins/timeline.mixin'
import { getVisTimelineItem } from '@/helpers/component-loader'
import useAgGridMixin from '@/mixins/agGridMixin.js'
import useActivityMixin from '@/mixins/activity-mixin'
import useValidationStore from '@/store/modules/validation'
import useClassifications from '@/mixins/classification'

const viewableValidationData = ref([])
const ability = useAbility()
const visTooltipMixin = useVisTooltipMixin()
const documentNLPMixin = useDocumentNLPMixin()
const timelineMixin = useTimelineMixin()
const timelineStore = useTimelineStore()
const agGridStore = useAgGridStore()
const uiStore = useUiStore()
const activityStore = useActivityStore()
const validationStore = useValidationStore()
const activityMixin = useActivityMixin()
const agGridMixin = useAgGridMixin()
const classifications = useClassifications()

const timelineClassifications = ref()

const visMinHeight = visTimelineOptions.visMinHeight
const visMaxHeight = visTimelineOptions.visMaxHeight

const firstLastClicked = ref(false)
const activeSwimlanes = ref([])
const activeSwimlanesUnordered = ref([])
const areGroupsCollapsed = ref(true)

const timelineItems = ref([])
const timelineColours = toRef(uiStore.state, 'visShowColour')
const defaultSwimlanes = [
  {
    id: 'validation',
    order: 1,
    key: 'validation',
    content: 'Validations',
    groupKey: 'validation',
    type: 'point',
    category: 'validation',
  },
  {
    id: 'activity',
    order: 2,
    key: 'activity',
    content: 'Activities',
    groupKey: 'activity',
    category: 'activity',
  },
  {
    id: 'rova',
    order: 3,
    key: 'rova',
    content: 'Rova',
    groupKey: 'rova',
    type: 'point',
    category: 'rova',
  },
  {
    id: 'rtt-clock',
    order: 5,
    key: 'rtt-clock',
    content: 'RTT Clock',
    groupKey: 'RTTClock',
    type: 'range',
    category: 'rtt',
  },
]

const classifierConfig = computed(() => documentNLPMixin.classifiers)

watch(
  [timelineMixin.filteredActivityList, classifications.filteredClassificationList, timelineColours],
  () => {
    // Filter out classifications that are to be hidden in the timeline
    timelineClassifications.value = classifications.filteredClassificationList.value.filter(
      (item) =>
        !item.Classifier || !classifierConfig.value[item.Classifier.toLowerCase()]?.hideInTimeline,
    )
    groupSwimlanes(uiStore.state.visTimelineGroupMode)
  },
  { deep: true },
)

const visRange = [
  'visZoomLast',
  'visZoomMonths',
  'visOffsetClicked',
  'visHiddenGroups',
  'visShowDetail',
]

uiStore.$onAction(
  ({
    name, // name of the action
    args, // array of parameters passed to the action
    after, // function to run after the action
  }) => {
    if (name !== 'updateUserDefaults') return

    after(() => {
      const [newValue, stateKey] = args

      // Update the timeline if any of the timeline range is changed
      if (visRange.includes(stateKey)) {
        if (stateKey === 'visZoomLast') {
          firstLastClicked.value = true
        }

        if (stateKey === 'visShowDetail') {
          // If the timeline detail level is changed, recreate it with current range
          // To ensure the items are displayed correctly
          timelineStore.visTimeline.destroy()
          renderTimeline()
          groupSwimlanes(uiStore.state.visTimelineGroupMode)
        }
        nextTick(() => {
          updateVisDateRange()
        })
      }

      // Update the swimlanes if any of the swimlane toggles are changed
      if (stateKey.startsWith('visShow')) {
        populateSwimlanes()
      }

      // Update the timeline if the fullscreen toggle is changed
      if (stateKey === 'isFullScreen') {
        if (uiStore.state.isFullscreen) {
          timelineStore.visTimeline.setOptions({ height: '90vh', maxHeight: '90vh' })
        } else {
          timelineStore.visTimeline.setOptions({ height: visMaxHeight })
        }
      }

      // Update the swimlanes if the group mode is changed
      if (stateKey === 'visTimelineGroupMode') {
        groupSwimlanes(newValue)
      }

      // Update the swimlane order if the order mode is changed
      if (stateKey === 'visTimelineOrderMode') {
        orderSwimlanes(newValue)
      }
    })
  },
)

const rangeOptions = ref({
  min: null,
  max: null,
  from: null,
  to: null,
})

const timelineOptions = {
  height: '400px',
  zoomKey: 'shiftKey',
  zoomMax: config.timeline.visTimelineOptions.visTimelineMaxZoom,
  groupOrder: 'order',
  // zoomMin: 1000 * 60 * 60 * 24 * 7 * 4, // 28d in milliseconds
  zoomMin: 1000 * 60 * 60 * 24, // 1 day in milliseconds
  stack: true,
  // EXPERIMENTAL CLUSTERING
  // cluster: {
  //   maxItems: 1,
  //   clusterCriteria: (item1, item2) => {
  //     if (!(item1.rova && item2.rova)) {
  //       return false
  //     }
  //     return item1.data.ParentCategory === item2.data.ParentCategory
  //   },
  // },
  margin: {
    item: 3,
    axis: 5,
  },

  tooltip: {
    followMouse: true,
    overflowMethod: 'cap',
    template: (item) => {
      // TODO: Make this dyamic if adding another tooltip type
      if (item.validation) {
        return visTooltipMixin.getValidationTooltipContent(item.data)
      }
      // RTT
      if (item.rtt) {
        return visTooltipMixin.getRTTTooltipContent(item.data)
      }
      // Rova
      if (item.rova) {
        return visTooltipMixin.getRovaTooltipContent(item.data)
      }
      if (item.rovaGroup) {
        return visTooltipMixin.getRovaGroupTooltipContent(item.data)
      }
      // Patient Activity
      return visTooltipMixin.getActivityTooltipContent(item)
    },
    delay: 300,
  },
}

// Gets the hidden groups based on timeline toggles
const hiddenGroups = computed(() => {
  const configurableSwimlanes = {
    rtt: 'RTT',
    rova: 'Rova',
    activity: 'Activities',
    validation: 'Validations',
  }

  return Object.keys(configurableSwimlanes).filter(
    (swimlane) => !uiStore.state[`visShow${configurableSwimlanes[swimlane]}`],
  )
})

const toggleNestedGroups = () => {
  areGroupsCollapsed.value = !areGroupsCollapsed.value

  activeSwimlanes.value.forEach((lane) => {
    lane.showNested = areGroupsCollapsed.value ? false : true
  })

  orderSwimlanes(uiStore.state.visTimelineGroupMode)
}

const groupSwimlanes = (groupMode) => {
  if (groupMode === 'none') {
    populateSwimlanes(true)
    rebuildTimelineItems(false)
    return
  }

  const swimlanes = []

  // Get unique groups from the timeline items to group by
  const groups = [
    ...new Set(
      [
        ...timelineStore.timeline.map((item) => item[groupMode]),
        ...timelineClassifications.value.map((item) => item[groupMode]),
        ...viewableValidationData.value.map((item) => item[groupMode]),
      ].filter((item) => item !== null && item !== undefined),
    ),
  ]

  // Add 'Unknown' if any groupMode is null or undefined
  if (
    [
      ...timelineStore.timeline,
      ...timelineClassifications.value,
      ...viewableValidationData.value,
    ].some((item) => item[groupMode] == null)
  ) {
    groups.push(null)
  }

  const uniqueGroups = [...new Set(groups)]

  uniqueGroups.forEach((content, index) => {
    const ids = []
    const isInitialPathway = timelineStore.initialPathwayID === content

    // Create child lanes (e.g., validation, activity, etc.)
    defaultSwimlanes.forEach((swimlane) => {
      const id = `${content}::${swimlane.key}`
      ids.push(id)
      swimlanes.push({
        ...swimlane,
        id,
        treeLevel: 2,
        groupKey: id,
      })
    })

    swimlanes.push({
      id: content ?? 'Unknown',
      content: isInitialPathway ? `Main Pathway<br>${content}` : content,
      groupKey: content,
      treeLevel: 1,
      order: index + 1,
      category: 'master',
      visible: true,
      nestedGroups: ids,
      showNested: !areGroupsCollapsed.value,
      isInitialPathway,
      className: isInitialPathway ? 'main-pathway master-lane' : 'master-lane',
    })
  })

  activeSwimlanes.value = activeSwimlanesUnordered.value = swimlanes

  // Update the swimlanes to include the new groups
  orderSwimlanes(uiStore.state.visTimelineOrderMode)
}

const orderSwimlanes = (orderMode) => {
  if (orderMode === 'none') {
    activeSwimlanes.value = activeSwimlanesUnordered.value
  }

  if (orderMode === 'asc') {
    activeSwimlanes.value = _.orderBy(activeSwimlanes.value, ['id'], ['asc'])
  }

  if (orderMode === 'desc') {
    activeSwimlanes.value = _.orderBy(activeSwimlanes.value, ['id'], ['desc'])
  }

  activeSwimlanes.value = _.sortBy(activeSwimlanes.value, (swimlane) =>
    swimlane.isInitialPathway ? 0 : 1,
  )

  activeSwimlanes.value = activeSwimlanes.value.map((item, index) => ({
    ...item,
    order: index,
  }))

  populateSwimlanes()
  rebuildTimelineItems(false)
}

const populateSwimlanes = (resetToDefault = false) => {
  if (resetToDefault || activeSwimlanes.value.length === 0) {
    activeSwimlanes.value = defaultSwimlanes
  }

  activeSwimlanes.value = activeSwimlanes.value.map((item) => ({
    ...item,
    visible: !hiddenGroups.value.includes(item.category),
  }))

  timelineStore.visTimeline?.setGroups(new DataSet(activeSwimlanes.value))
}

const getActivityStatusIconName = (activityStatus, isIllogical = null) => {
  if (isIllogical) {
    return activityMixin.getStatusIcon('illogical')
  }
  if (!activityStatus) {
    return activityMixin.getStatusIcon('Unknown')
  }

  return activityMixin.getStatusIcon(activityStatus)
}

const getRovaClassificationIconName = (classification) => {
  if (classification.ClassificationStatus === 'classified') {
    return documentNLPMixin.getClassificationStatusIcon(classification.Classifier)
  }

  return documentNLPMixin.getClassificationLabelIcon(classification)
}

const formActivityItem = (item, isMaster = false) => {
  // Generate the timeline item content HTML
  const timelineItemHTML = getVisTimelineItem({
    title: activityMixin.getActivityMediumName(item.ActivityType),
    icon: activityMixin.getActivityIcon(item.ActivityCategory),
    statusIconMapping: getActivityStatusIconName(item.ActivityStatus, item.illogical),
    iconType: item.illogical ? 'fas' : 'fal',
    statusIconClassName: item.illogical
      ? 'has-text-red'
      : `has-text-${activityMixin.getStatusColor(item.ActivityStatus)}`,
    itemType: 'activity',
    itemId: item.ActivityUniqueID,
    start: item.ActivityDate,
    ...item,
  })

  const borderClass = uiStore.state.visShowColour
    ? `has-border-${activityMixin.getActivityColor(item.ActivityCategory)}`
    : 'no-color'
  const highlightClass =
    uiStore.state.visShowIllogicalColours && item.illogical
      ? activityMixin.getActivityIllogicalHighlightClass({
          illogical: item.illogical,
          illogicalSeverity: item.illogicalSeverity,
        })
      : ''

  /**
   * If the "visTimelineGroupMode" variable is set to a specific field then the item needs to use the corresponding
   * field/group id rather than the default group id
   */
  const group =
    uiStore.state.visTimelineGroupMode !== 'none'
      ? `${item[uiStore.state.visTimelineGroupMode]}::activity`
      : 'activity'

  return {
    className: `${highlightClass} ${borderClass} has-background-white`,
    start: item.ActivityDate,
    content: timelineItemHTML,
    group,
    id: isMaster ? `master::${item.ActivityUniqueID}` : `activity::${item.ActivityUniqueID}`, // Ensure unique ID for master
    ...item,
    order: 1,
  }
}

const formRovaItem = (item) => {
  const matchedValidation = validationStore.validationHistory.find(
    (v) => v.ClassificationId === item.ClassificationId,
  )

  const validationStatus =
    validationStore.rovaValidationStatusColours[matchedValidation?.ValidationType]

  // Generate the timeline item content
  const timelineItemHTML = getVisTimelineItem({
    icon: getRovaClassificationIconName(item),
    itemType: 'classification',
    itemId: item.ActivityUniqueID,
    validationStatus,
  })

  let className = 'has-background-white has-text-black'

  if (uiStore.state.visShowColour) {
    const isClassified = item.ClassificationStatus === 'classified'
    const hasColour = documentNLPMixin.getClassificationStatusColor(item.Classifier)

    if (isClassified && hasColour) {
      className = `has-text-${
        ['white', 'pale-red'].includes(hasColour) ? 'grey-dark has-border-grey-dark' : 'white'
      } has-background-${hasColour}`
    }
  }

  const group =
    uiStore.state.visTimelineGroupMode !== 'none'
      ? `${item[uiStore.state.visTimelineGroupMode]}::rova`
      : 'rova'

  return {
    className,
    start: dayjs(
      documentNLPMixin.getDocumentFileDateWithFallback(item.FileDate, timelineStore.timeline),
    ).format(config.LOCALE.SQL_DATE_FORMAT),
    content: timelineItemHTML,
    group,
    rova: true,
    id: agGridMixin.getAgRowId('classifications', item),
    data: item,
    order: 3,
  }
}

const formRovaGroupItem = (group) => {
  const groupName = group[0]

  const timelineItemHTML = getVisTimelineItem({
    items: group[1],
    itemType: 'classification',
    itemId: groupName,
    isGroup: true,
    parentCategory: groupName,
  })

  const numParentCategories = Object.keys(_.groupBy(group[1], (item) => item.ParentCategory)).length

  const className = `has-background-grey-dark has-text-white rova-group-item is-${Math.ceil(numParentCategories / 2)}-tall`

  const timelineGroup =
    uiStore.state.visTimelineGroupMode !== 'none'
      ? `${group[1][0][uiStore.state.visTimelineGroupMode]}::rova`
      : 'rova'

  return {
    className,
    start: dayjs(
      documentNLPMixin.getDocumentFileDateWithFallback(
        group[1][0].FileDate,
        timelineStore.timeline,
      ),
    ).format(config.LOCALE.SQL_DATE_FORMAT),
    content: timelineItemHTML,
    group: timelineGroup,
    rovaGroup: true,
    id: `classification::${group[1][0].FileNumber}|${group[1][0].ActivityUniqueID}`,
    data: group[1],
    order: 3,
  }
}

const formValidationItem = (item) => {
  const validationMeta = validationStore.getValidationvalidationMeta(
    item.ValidationType || item.ValidationTarget,
  )

  const timelineItemHTML = getVisTimelineItem({
    icon: validationMeta.icon,
  })

  const group =
    uiStore.state.visTimelineGroupMode !== 'none'
      ? `${item[uiStore.state.visTimelineGroupMode]}::validation`
      : 'validation'

  return {
    className: `has-background-${validationMeta.colour}`,
    start: item.ValidatedOn,
    content: timelineItemHTML,
    data: item,
    group,

    id: agGridMixin.getAgRowId('validationHistory', item),
    validation: true,
    order: 3,
  }
}

const formRTTItem = (item, isMaster = false) => {
  const group = isMaster
    ? item.PathwayID
    : uiStore.state.visTimelineGroupMode !== 'none'
      ? `${item[uiStore.state.visTimelineGroupMode]}::rtt-clock`
      : 'rtt-clock'

  return {
    className:
      item.type !== 'RTT Clock'
        ? `has-border-${activityMixin.getStatusColor(item.activityType)}`
        : ['open', 'active', 'running'].includes(item.status.toLowerCase())
          ? item.duration <= 126
            ? 'has-background-pale-green'
            : item.duration < 365
              ? 'has-background-pale-orange'
              : 'has-background-pale-red'
          : 'has-background-white',
    start: dayjs(item.start).format(config.LOCALE.SQL_DATE_FORMAT),
    end: item.end ? dayjs(item.end).format(config.LOCALE.SQL_DATE_FORMAT) : null,
    group,
    ...getRTTItemConfig(item),
    rtt: true,
    data: item,
    type: 'range',
    order: 2,
  }
}

const getRTTItemConfig = (item) => {
  const rttCodeValue =
    !uiStore.state.visShowLocalRTTCodes || !item.statusLocal
      ? item.statusNational
      : item.statusLocal

  if (item.type === 'RTT Status' && !item.isIllogical) {
    return {
      content: rttCodeValue,
      type: 'point',
    }
  } else if (item.isIllogical) {
    return {
      content: rttCodeValue,
      type: 'box',
      className: `has-background-${activityMixin.getStatusColor(
        'illogical',
      )} has-text-${activityMixin.getStatusColor('illogical')} bold`,
    }
  }

  let totalDaysRunning = daysBetweenDates(
    getDateFromSSQL(item.start),
    item.end ? getDateFromSSQL(item.end) : new Date(),
  )

  const units = config.timeline.RTTClockDisplayUnits
  // Format as days or weeks according to config
  const amount = units === 'days' ? totalDaysRunning : Math.floor(totalDaysRunning / 7)

  return {
    content: `${amount} ${units} (${item.status})`,
    type: 'range',
  }
}

/**
 * Update the swimlanes to remove the empty swimlanes
 */
const updatePopulatedSwimlanes = () => {
  // Get the populated swimlane groups
  const populatedSwimlaneGroups = [...new Set(timelineItems.value.map((item) => item.group))]

  // Remove the empty swimlanes
  const populatedSwimlanes = activeSwimlanes.value.filter((s) =>
    populatedSwimlaneGroups.includes(s.key),
  )

  // Add any missing swimlanes
  const missingSwimlanes = defaultSwimlanes.filter(
    (s) =>
      populatedSwimlaneGroups.includes(s.key) &&
      populatedSwimlanes.find((p) => p.key === s.key) === undefined,
  )

  activeSwimlanes.value = [...populatedSwimlanes, ...missingSwimlanes].sort(
    (a, b) => a.order - b.order,
  )

  // Update the swimlanes to remove the empty swimlanes
  populateSwimlanes()
}

const rebuildTimelineItems = (repopulateSwimlanes = true) => {
  let itemsArr = []

  // Filter out activities without a date
  let activityItems = timelineMixin.filteredActivityList.value.filter((item) => item.ActivityDate)

  // Group activities by the current group mode (e.g., 'validation', 'activity', etc.)
  const groupedActivities = _.groupBy(
    activityItems,
    (item) => item[uiStore.state.visTimelineGroupMode],
  )

  // Appended the activity items
  _.forEach(activityItems, (item) => {
    itemsArr.push(formActivityItem(item))
  })

  // For each group, get the first and last activity and append them to the master swimlane
  Object.keys(groupedActivities).forEach((groupKey) => {
    const activitiesInGroup = groupedActivities[groupKey]
    let firstActivity, lastActivity

    if (activitiesInGroup.length > 0) {
      firstActivity = _.minBy(activitiesInGroup, 'ActivityDate')

      // Create and push the first activity item into the corresponding master swimlane
      itemsArr.push({
        ...formActivityItem(firstActivity, true),
        group: groupKey === 'null' ? 'Unknown' : groupKey, // Ensure it's added to the master swimlane
      })
    }

    // Create an array with the first activity removed, to avoid duplicate first/last items
    // This can happen if all items have the same date, and causes duplicate id error in vis-timeline
    const activitiesInGroupWithoutFirst = activitiesInGroup.filter((item) => item !== firstActivity)

    if (activitiesInGroupWithoutFirst.length > 0) {
      // Create and push the last activity item into it's master swimlane if there are more than one activity
      lastActivity = _.maxBy(activitiesInGroupWithoutFirst, 'ActivityDate')

      itemsArr.push({
        ...formActivityItem(lastActivity, true),
        group: groupKey === 'null' ? 'Unknown' : groupKey, // Ensure it's added to the master swimlane
      })
    }
  })

  // Group the classifications by Parent Category
  const groupedRovaItems = _.groupBy(timelineClassifications.value, 'FileNumber')

  if (!uiStore.state.visShowDetail) {
    // Appended the Rova groups
    Object.entries(groupedRovaItems)?.forEach((group) => {
      itemsArr.push(formRovaGroupItem(group))
    })
  } else {
    // Appended the Rova items
    _.forEach(timelineClassifications.value, (item) => {
      itemsArr.push(formRovaItem(item))
    })
  }

  // Appended the RTT items, also add the pathway range items to the master rows
  const clocks = timelineStore.rttData.filter((item) => item.type === 'RTT Clock')
  clocks.forEach((item) => {
    itemsArr.push(formRTTItem(item))
    itemsArr.push(formRTTItem(item, true))
  })

  // Filter out classification validations from the validations swimlane
  // unless permission has been added to keep them in view
  viewableValidationData.value = validationStore.validationHistory.filter(
    (v) =>
      ability.can('view', 'context:classificationValidationInSwimlane') ||
      v.ValidationTarget !== 'Classification',
  )

  // Appended the Validation items
  _.forEach(viewableValidationData.value, (item) => {
    itemsArr.push(formValidationItem(item))
  })

  if (!itemsArr.length) {
    return
  }

  itemsArr = itemsArr.filter((item) => item.start !== undefined)

  itemsArr = itemsArr.filter((elm) => {
    return typeof elm === 'object' && elm !== null
  })
  timelineItems.value = itemsArr

  // Update the swimlanes
  if (repopulateSwimlanes) {
    updatePopulatedSwimlanes()
  }

  // This ordering is to ensure that the activities are always on top of the RTT time ranges in the 'master' swimlane
  if (itemsArr && timelineStore.visTimeline) {
    timelineStore.visTimeline.setOptions({
      order: function (a, b) {
        return b.order - a.order
      },
    })
    timelineStore.visTimeline.setData({ items: new DataSet(itemsArr) })

    updateVisDateRange()
  }
}

onMounted(() => {
  uiStore.state.visRangeOffset = 0

  renderTimeline()
})

const updateVisVisibleWindow = (event) => {
  timelineStore.visTimeline.setWindow(event.from, event.to)
}

const updateVisDateRange = () => {
  // Get the codes of hidden groups
  const hiddenCodes = _.uniq(
    timelineItems.value
      .map((arr1Item) =>
        uiStore.state.visHiddenGroups.filter((arr2Item) => arr1Item.group === arr2Item.order),
      )
      .flat()
      .map((item) => item.order),
  )

  // Sort from A-Z in order to easily get the max and min values
  const unsortedItems = timelineItems.value
  const range = unsortedItems
    .sort((a, b) => {
      return new Date(b.start) - new Date(a.start)
    })
    // remove the hidden items from the range
    .filter((item) => !hiddenCodes.includes(item.group))

  // Construct a new object to prevent multiple delayed constructions
  const newRangeOptions = { min: null, max: null }

  // Parse dates from the range
  const minDate = dayjs(range[range.length - 1]?.start, config.LOCALE.SQL_DATE_FORMAT)
  const maxDate = dayjs(range[0]?.start, config.LOCALE.SQL_DATE_FORMAT)

  // Calculate the difference in months between min and max dates
  const monthsDifference = maxDate.diff(minDate, 'month')

  // Calculate the padding in days, relative to the zoom level if set, or total time period in months
  const xPadding = uiStore.state.visZoomMonths <= 0 ? monthsDifference : uiStore.state.visZoomMonths

  // Set min and max to include all items
  // Adjust min and max by subtracting/adding padding
  newRangeOptions.min = minDate.subtract(xPadding, 'day').valueOf()
  newRangeOptions.max = maxDate.add(xPadding, 'day').valueOf()

  if (range.length > 0 && uiStore.state.visZoomMonths > 0) {
    if (uiStore.state.visZoomLast) {
      // Set the range to include the last defaultZoomMonths
      newRangeOptions.from = dayjs(newRangeOptions.max)
        .subtract(uiStore.state.visZoomMonths, 'months')
        .valueOf()
      newRangeOptions.to = newRangeOptions.max
    }

    if (!uiStore.state.visZoomLast) {
      // Set the range to include the first defaultZoomMonths
      newRangeOptions.to = dayjs(newRangeOptions.min)
        .add(uiStore.state.visZoomMonths, 'months')
        .valueOf()
      newRangeOptions.from = newRangeOptions.min
    }
  }

  if (uiStore.state.visZoomMonths == 0) {
    // Set the selected range to include all items if the defaultZoomMonths is set to 0
    uiStore.state.visRangeOffset = 0
    newRangeOptions.from = newRangeOptions.min
    newRangeOptions.to = newRangeOptions.max
  }
  // If the first/last switch was clicked, reset the offset
  if (firstLastClicked.value) {
    uiStore.state.visRangeOffset = 0
    firstLastClicked.value = false
  }

  // Apply the offset to .from and .to (restrict to newRangeOptions.min/max), and reset the offset.
  if (uiStore.state.visRangeOffset < 0) {
    newRangeOptions.from = Math.max(
      dayjs(uiStore.visCurrentStart).subtract(uiStore.state.visZoomMonths, 'months').valueOf(),
      newRangeOptions.min,
    )
    newRangeOptions.to = dayjs(newRangeOptions.from)
      .add(uiStore.state.visZoomMonths, 'months')
      .valueOf()
    uiStore.state.visRangeOffset = 0
  }

  if (uiStore.state.visRangeOffset > 0) {
    newRangeOptions.to = Math.min(
      dayjs(uiStore.visCurrentEnd).add(uiStore.state.visZoomMonths, 'months').valueOf(),
      newRangeOptions.max,
    )
    newRangeOptions.from = dayjs(newRangeOptions.to)
      .subtract(uiStore.state.visZoomMonths, 'months')
      .valueOf()
    uiStore.state.visRangeOffset = 0
  }

  uiStore.visCurrentStart = newRangeOptions.from
  uiStore.visCurrentEnd = newRangeOptions.to

  if (!timelineStore.dateSliderChanged && !timelineStore.timelineHeaderFilterChanged) {
    rangeOptions.value = newRangeOptions
    if (newRangeOptions.from && newRangeOptions.to) {
      timelineStore.visTimeline.setWindow(newRangeOptions.from, newRangeOptions.to)
    }
  }

  if (hiddenCodes.length === 0) {
    timelineStore.timelineFilterChanged = false
    timelineStore.dateSliderChanged = false
  }
  timelineStore.timelineHeaderFilterChanged = false
}

rebuildTimelineItems()
// updateVisDateRange()

const renderTimeline = () => {
  let container = document.getElementById('visualization')
  const data = timelineItems.value.filter((elm) => elm.start !== undefined)

  timelineStore.visTimeline = new Timeline(
    container,
    new DataSet(data),
    new DataSet(activeSwimlanes.value),
    {
      minHeight: visMinHeight,
      maxHeight: visMaxHeight,
      ...timelineOptions,
    },
  )

  // @todo - goran : we should change this to store in a Vue data which other components 'watch' for changes
  // this will allow us to (for example) check if ActivityUniqueID is set when loading ag-timeline and perform the row.setSelected(true)
  timelineStore.visTimeline.on('click', (event) => {
    // Ensure an actually cell was clicked
    if (!event.item) {
      return false
    }

    // Split to extract desired fields
    const fields = event.item.split('::')

    if (fields && fields.length > 1) {
      const fieldType = fields[0]
      // Handle activity related clicks

      if (fieldType === 'activity') {
        const item = _.find(timelineItems.value, { ActivityUniqueID: fields[1] })
        timelineStore.selectedActivity = item

        if (item) {
          timelineStore.visTimeline.setSelection(`activity::${item.ActivityUniqueID}`)
        }

        // TODO: replace with global events
        // bus.$emit('timeline-scroll-to-activity', item)
        timelineStore.eventViewerTab = 'activities'

        // TODO Does this work when not on the activities tab?
        documentNLPMixin.selectRowNode(agGridStore.agGrids.activities.api, item.ActivityUniqueID)
        activityStore.loadDetails({
          ActivityID: item.ActivityOID,
          ActivityType: item.ActivityType,
        })

        documentNLPMixin.selectDocumentViewer({ ActivityUniqueID: item.ActivityUniqueID }, true)
      } else if (fieldType === 'classification') {
        const record = _.find(timelineItems.value, { id: event.item })

        // @todo move to timeline-ui mixin
        if (record && (record.rova || record.rovaGroup)) {
          // Naviate to a classifications grid if no document grid is being shown
          if (
            !Object.keys(documentNLPMixin.documentAgGrids.value).includes(
              timelineStore.eventViewerTab,
            )
          ) {
            timelineStore.eventViewerTab = 'classifications'
          }

          documentNLPMixin.selectAgRows(record)
        }
      } else if (fieldType === 'validation') {
        const record = timelineItems.value.find((i) => i.id === event.item)

        // @todo move to timeline-ui mixin
        if (record) {
          timelineStore.eventViewerTab = 'validations'
          documentNLPMixin.selectAgRows(record)
        }
      }
    }
  })

  timelineStore.visTimeline.on('rangechanged', (event) => {
    // Update both params at once to prevent multiple reconstructions
    const newRangeOptions = rangeOptions.value

    newRangeOptions.from = event.start.valueOf()
    newRangeOptions.to = event.end.valueOf()

    rangeOptions.value = newRangeOptions
  })
}

defineExpose({
  toggleNestedGroups,
  areGroupsCollapsed,
})
</script>

<style>
.fullscreen .vis-timeline-wrapper {
  height: calc(100% - 120px);
}
.fullscreen .vis-timeline-wrapper #visualization {
  height: calc(100% - 65px);
}
.fullscreen .vis-timeline-wrapper #visualization .vis-timeline {
  max-height: none !important;
  height: 100% !important;
}

.vis-timeline-fullscreen {
  height: calc(100% - 200px);
}
.vis-timeline-fullscreen #visualization {
  height: 100%;
}
.vis-label.main-pathway {
  /* highlight main pathway master swimlane */
  background-color: #fff785 !important;
  font-weight: bold;
  text-align: center;
}
.master-lane {
  /* highlight master swimlane */
  background-color: #f5f5f5 !important;
}
.vis-left .vis-label {
  font-size: 0.8em;
  padding-left: 5px;

  & .vis-inner {
    padding-left: 5px !important;
  }
}
</style>
