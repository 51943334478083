import { defineStore } from 'pinia'
import _ from 'lodash'
import config from '@/config'
import { ref, reactive, watch } from 'vue'
import { documentService } from '@/services/endpoints/document'
import useFilterStore from '@/store/modules/filter'
import useConfigStore from '@/store/modules/config'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import useTimelineMixin from '@/mixins/timeline.mixin'
import useAbility from '@/services/ability.js'
import useTimelineStore from '@/store/modules/timeline'
import useValidationStore from '@/store/modules/validation'

export default defineStore('document', () => {
  const timelineMixin = useTimelineMixin()
  const documentNLPMixin = useDocumentNLPMixin()
  const ability = useAbility()

  const filterStore = useFilterStore()
  const configStore = useConfigStore()
  const timelineStore = useTimelineStore()
  const validationStore = useValidationStore()

  const documentModels = ref([])
  const classificationList = ref([])
  const selectedFileNumbers = ref([])
  const selectedFileNumbersChanged = ref(false)
  const focusedFileNumber = ref(null)
  const hasUnlinkedDocuments = ref(null)

  const loadingState = reactive({
    initialDocuments: 'PENDING',
    documents: 'READY',
    documentModels: 'READY',
  })
  const document = reactive({})
  const error = ref(false)
  const loadedOnce = ref(false)
  const documents = ref([])
  const selectedDocumentPanelView = ref('text')

  // For embedded applications, we need to update the data when the pathway changes
  watch(
    () => configStore.appParams.PTLUniqueID,
    (newValue, oldValue) => {
      if (newValue !== oldValue && oldValue) {
        // Reset the initial PTLUniqueID
        timelineStore.initialPTLUniqueID = newValue
        timelineMixin.setPTLUniqueId([[newValue]])
      }
    },
  )

  const getTotalDocumentsByActivityId = (ActivityUniqueID) => {
    const docs =
      _.groupBy(
        classificationList.value.filter((p) => p.ActivityUniqueID === ActivityUniqueID),
        'FileId',
      ) || {}

    return Object.keys(docs).length
  }

  const getDocumentsByActivityUID = () => (ActivityUniqueID) => {
    return (
      _.groupBy(
        classificationList.value.filter((p) => p.ActivityUniqueID === ActivityUniqueID),
        'FileId',
      ) || {}
    )
  }

  const clearDocumentData = () => {
    documents.value = []
    classificationList.value = []
    documentModels.value = []
  }

  const queryDocuments = async (query) => {
    // NOTE: 'Documents' here refer to classifications
    documentService.getLinkStatus({ tblPTLUniqueID: query.tblPTLUniqueID }).then((response) => {
      hasUnlinkedDocuments.value = response
    })

    const docs = await documentService.getList(query)

    // Check for documents, if none then return
    // This prevents an infinite loop if there are no documents
    if (!docs.length) {
      return
    }

    // Add confidence level to documents
    docs.forEach((doc) => {
      doc.ConfidenceLevel = getConfidenceLevel(doc.Classifier)
      doc.ParentCategory = getParentCategory(doc.Classifier)
    })

    // #8195 Filter out documents based on currently selected PTLUIDS
    // Return documents which have a PTLUniqueID matching the currently selected ones or where PTLUID is null
    const pathwayDocuments = docs.filter((item) => {
      return query.tblPTLUniqueID.flat().includes(item.PTLUniqueID) || !item.PTLUniqueID
    })

    documents.value = pathwayDocuments

    filterLowConfidenceClassifications(pathwayDocuments)

    // Set initial documents that are related to pathways
    // This means ignore any documents that are not linked to a patient or do not have a PTLUniqueID #20133
    selectedFileNumbers.value = docs
      .filter((doc) => doc.LinkedTo !== 'Patient' && doc.PTLUniqueID)
      .map((doc) => doc.FileNumber)

    if (configStore.appParams.FileId || configStore.appParams.FileNumber) {
      const selectDocument = pathwayDocuments.find(
        (d) =>
          d.FileNumber === configStore.appParamsFileNumber ||
          d.FileId.toUpperCase() === configStore.appParams.FileId.toUpperCase(),
      )

      // If there is no selected document, it means that it belongs to a different pathway
      // Get the PTLUniqueID from document model and load the additional pathway
      if (!selectDocument && configStore.appParams.FileId) {
        const PTLUniqueIDsString =
          configStore.appParams.ParentPTLUniqueID || configStore.appParams.PTLUniqueID
        const PTLUniqueIDs = PTLUniqueIDsString.split('|')
        const documentModel = await documentService.queryDocumentModels({
          FileId: configStore.appParams.FileId,
        })
        timelineMixin.setPTLUniqueId([
          ...PTLUniqueIDs.map((p) => [p]),
          [documentModel[0].PTLUniqueID],
        ])
        return
      }

      selectedFileNumbers.value.push(selectDocument.FileNumber)
      documentNLPMixin.selectAgRows({ data: selectDocument })
    }

    if (!configStore.appParams.PTLUniqueID) {
      timelineMixin.setPTLUniqueId([docs[0].PTLUniqueID])
    }
  }

  const filterLowConfidenceClassifications = (classifications) => {
    // Group classifications by FileNumber
    const groupedClassifications = _.groupBy(classifications, 'FileNumber')
    // For each group of classifications with the same FileNumber
    Object.keys(groupedClassifications).forEach((fileNumber) => {
      const docs = groupedClassifications[fileNumber]

      // if one of them has a High confidence level,
      const highConfidenceDoc = _.find(docs, { ConfidenceLevel: 'High' })

      if (highConfidenceDoc) {
        // remove any Low confidence level ones with the same ParentCategory and FileNumber (ie from the same document)
        const parentCategory = highConfidenceDoc?.ParentCategory
        _.remove(
          documents.value,
          (doc) =>
            doc.ConfidenceLevel === 'Low' &&
            doc.ParentCategory === parentCategory &&
            doc.FileNumber === highConfidenceDoc.FileNumber,
        )
      }
    })
  }

  const updateFilterOptions = () => {
    const data = documents.value

    // Update the filter options
    for (let filterField of config.CLASSIFICATION_FILTER_FIELDS) {
      let filterOptions = _.map(_.uniqBy(data, filterField), (item) => {
        return {
          attr: item[filterField],
          name: item[filterField],
        }
      }).filter((elm) => {
        return !!elm.name
      })

      filterStore.setFilterOptions({ attr: filterField, options: filterOptions })
    }
  }

  const updateClassifications = () => {
    let data = documents.value.filter((doc) => selectedFileNumbers.value.includes(doc.FileNumber))

    // Reset file numbers, otherwise they will exclude ones from documents that were previously filtered out
    selectedFileNumbers.value = data.map((doc) => doc.FileNumber)

    // Filter out unselected documents from ag-documents
    const filteredDocuments = data.filter((doc) => {
      return selectedFileNumbers.value.includes(doc.FileNumber)
    })

    const validations = validationStore.validationHistory.filter(
      (c) => c.ValidationTarget === 'Classification',
    )

    classificationList.value = filteredDocuments.map((classification) => {
      // Find the latest matching validation by ClassificationId
      const latestValidation = validations.find(
        (validation) => validation.ClassificationId === classification.ClassificationId,
      )

      // Add the latest validation to the classification
      return {
        ...classification,
        Outcome: latestValidation ? latestValidation.ValidationType : null,
      }
    })

    updateFilterOptions()
  }

  const queryDocumentModels = async (query, additionalClassifications) => {
    loadingState.documentModels = 'LOADING'
    const data = await documentService.queryDocumentModels(query, additionalClassifications)

    loadingState.documentModels = 'DONE'
    // #14622: Filter out any classifications that are not in the documents list
    if (!ability.can('view', 'document:missingClassificationsFromTimeline')) {
      for (const document of data) {
        document.classifications = document.classifications.filter((classification) => {
          return classificationList.value.find((c) => c.ClassificationId === classification._id)
        })
      }
    }

    documentModels.value = data || []

    if (documentModels.value.length > 0 && documentModels.value[0].classifications) {
      documentModels.value[0].NumClassifications = documentModels.value[0].classifications.length
    }

    return documentModels.value
  }
  const getDocument = async (query) => {
    const data = await documentService.get(query)
    document.value = data
  }
  const setAllLoadingStates = (newState) => {
    loadingState.documents = newState
    loadingState.documentModels = newState
  }

  const getConfidenceLevel = (classifier) => {
    const classifiers = configStore.getConfigByKey('web-config.documents.classifiers')
    return classifiers[`${classifier?.toLowerCase()}`]?.confidenceLevel
  }

  const getParentCategory = (classifier) => {
    const classifiers = configStore.getConfigByKey('web-config.documents.classifiers')
    return classifiers[`${classifier?.toLowerCase()}`]?.parentCategory
  }

  return {
    documentModels,
    classificationList,
    selectedFileNumbers,
    selectedFileNumbersChanged,
    focusedFileNumber,
    hasUnlinkedDocuments,
    loadingState,
    document,
    error,
    loadedOnce,
    documents,
    selectedDocumentPanelView,
    getTotalDocumentsByActivityId,
    getDocumentsByActivityUID,
    queryDocuments,
    updateClassifications,
    queryDocumentModels,
    getDocument,
    setAllLoadingStates,
    getConfidenceLevel,
    clearDocumentData,
  }
})
