<template>
  <div
    class="columns classification-group is-multiline has-background-transparent"
    :class="subGroups.length > 2 && 'is-flex'"
  >
    <div
      v-for="group in subGroups"
      :key="group"
      class="column is-half classification-group-item p-0 is-align-items-center is-justify-content-center"
      :class="[styleItem(group[0]).class, subGroups.length > 2 ? 'is-flex' : '']"
      :style="{ border: styleItem(group[0]).border }"
    >
      <div
        :data-itemType="itemType"
        :data-cy="`timeline-${itemType}-item`"
        class="timeline-group-inner-item"
      >
        {{ `${group.length}` }}
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  itemType: {
    type: String,
    default: '',
  },
  itemId: {
    type: String,
    default: '',
  },
  isGroup: {
    type: Boolean,
    default: true,
  },
})

const documentNLPMixin = useDocumentNLPMixin()

const subGroups = Object.values(_.groupBy(props.items, 'ParentCategory'))

const styleItem = (item) => {
  const background = documentNLPMixin.getClassificationStatusColor(item.Classifier)

  return {
    class: `has-background-${background} ${background === 'white' ? 'has-text-grey-dark has-border-grey-dark' : ''}`,
    border: background === 'white' ? '1px solid grey' : 'none',
  }
}
</script>

<style lang="less" scoped>
.classification-group-item,
.timeline-group-inner-item {
  height: 15px;
  min-width: 15px;
  font-size: 10px;
  border-radius: 2px !important;
  &.is-flex {
    height: 12px;
    min-width: 12px;
    font-size: 9px;
    border-radius: 0 !important;
  }
}

.classification-group {
  position: relative;
  max-width: 24px;
  top: -0.25rem;
}
</style>
