<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="filters">
    <n-filters-group
      :conditions="filterStore.segment.conditions"
      :segment-type="filterStore.segment.type"
    />
    <div class="filters--add-segment n-ml-3">
      <a-dropdown v-if="filterStore.addGroupEnabled || !filterStore.segment.conditions.length">
        <a data-cy="filters-dropdown" class="ant-dropdown-link" href="#">
          <i class="n-m-0 fal fa-plus" />
          Add Filter
        </a>
        <template #overlay>
          <div class="filters--adder-dd">
            <filter-list-menu @click="filtersMixin.addGroup" />
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script setup>
import useFilterStore from '@/store/modules/filter'
import useFiltersMixin from '@/mixins/filters-mixin'
import NFiltersGroup from '@/components/filters/filter-group.vue'
import FilterListMenu from '@/components/filters/filter-list-menu.vue'

const filterStore = useFilterStore()
const filtersMixin = useFiltersMixin()
</script>

<style lang="less">
.filters {
  &--adder-dd {
    border-radius: 4px;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    width: 360px;
    margin: 0 auto;
    & .ant-dropdown-menu-item-group-list {
      padding: 0;
    }
  }
  &--type-selector {
    display: inline;
    &__container {
      min-width: 55px;
      font-weight: bold;
      & .ant-select-selection--single {
        background-color: transparent;
        border: none;
      }
      & .ant-select-arrow-icon {
        display: none;
        visibility: hidden;
      }
      & .ant-select-selection-selected-value {
        padding: 0;
        text-align: center;
        width: 100%;
      }
    }
  }
}
.filters-dd {
  padding: 1.25rem !important;
  z-index: 1000 !important;
  top: 64px !important;
}
.filters--add-segment {
  display: inline;
  padding: 8px 0;
  margin-bottom: 5px;
  margin-left: 15px !important;
  font-weight: 600;
  white-space: nowrap;
}
@ss-menu-hover-bg: #e7e7e7;
@ss-menu-hover-color: #5e5e5e;

.filter-group {
  margin-bottom: 8px;
  display: inline;
  &--container {
    display: inline;
  }
  &--item {
    display: inline-block;
  }
  &--item:first-of-type > div:first-of-type .filter-block--container {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &--connector {
    background-color: #fff;
    border-right: 1px solid #fff;
    opacity: 1;
    display: inline-block;
    line-height: 20px;
    vertical-align: bottom;
    position: relative;
    min-width: 40px;
    &__content {
      display: block;
      background-color: #f6f6f6;
      font-weight: 600;
      color: #737376;
      border: 1px solid transparent;
      & .ant-select-arrow {
        display: none !important;
      }
      & .ant-select-selection__rendered {
        margin-right: 12px;
      }
      & .ant-select-selection--single {
        background-color: transparent;
      }
    }
  }
  &--add {
    background-color: #fff;
    border-left: 1px solid #fff;
    opacity: 1;
    display: inline-block;
    line-height: 20px;
    vertical-align: bottom;
    position: relative;
    &__content {
      display: block;
      color: #737376;
      background-color: #f6f6f6;
      padding: 8px 8px 8px 10px;
      -webkit-text-decoration-line: none;
      text-decoration-line: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid transparent;
    }
  }
}
.filter-block {
  display: inline-block;
  max-width: 400px;
  &--container {
    background-color: #ebebeb;
    border: 1px solid transparent;
    color: #222;
    box-sizing: border-box;
    transition:
      box-shadow linear 40ms,
      border-color linear 0.2s;
    position: relative;
  }
  &--content {
    max-width: 400px;
    padding: 8px 12px;
    &:hover {
      background-color: @ss-menu-hover-bg;
      color: @ss-menu-hover-color !important;
      cursor: pointer;
      .filter-block--title {
        color: @ss-menu-hover-color;
      }
      .filter-block--delete {
        display: block;
        & path {
          fill: @ss-menu-hover-color;
        }
      }
    }
  }
  &--title {
    flex: 1 0 auto;
    max-width: 100%;
    line-height: 20px;
    font-size: 13px;
  }
  &--condition {
    line-height: 20px;
  }
  &--delete {
    position: absolute;
    display: none;
    right: 8px;
    top: 10px;
    cursor: pointer;
    padding-left: 6px;
    background-color: @ss-menu-hover-bg;
    color: @ss-menu-hover-color;
  }
}
</style>
