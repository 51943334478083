<template>
  <ag-grid-vue
    data-cy="rova-classifications-grid"
    style="width: 100%; height: 80vh"
    :modules="agGridMixin.agModules"
    class="classification-table"
    :class="uiStore.state.isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham'"
    :column-defs="columnDefs"
    :default-col-def="defaultColDef"
    :side-bar="agTimelineDefs.sidebar"
    :status-bar="agTimelineDefs.statusPanels"
    :tools-panel="agTimelineDefs.toolPanels"
    :row-drag="agTimelineDefs.rowDrag"
    :animate-rows="true"
    :master-detail="true"
    :suppress-no-rows-overlay="true"
    :row-group-panel-show="'always'"
    :enable-cell-text-selection="false"
    :enable-browser-tooltips="true"
    :suppress-copy-rows-to-clipboard="true"
    row-selection="multiple"
    :pivot-panel-show="'always'"
    :enable-range-selection="true"
    :enable-charts="true"
    :get-row-id="getRowNodeId"
    @row-clicked="documentNLPMixin.selectAgRows"
    @grid-ready="(params) => onGridReady(params, 'classifications')"
  />
</template>

<script setup>
import _ from 'lodash'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
// import nTime from '@/mixins/n-time'
import { getNIcon } from '@/helpers/component-loader'
import { AgGridVue } from 'ag-grid-vue3'
import useAgGridMixin from '@/mixins/agGridMixin'
import defaultColDef from '@/mixins/globalDefaultColDef'
import useDocumentStore from '@/store/modules/document'
import { computed, watch, ref } from 'vue'
import useClassifications from '@/mixins/classification'
import useAbility from '@/services/ability.js'
import agTimelineDefs from '@/config/ag-grid-defs/ag-activities.js'
import agClassificationListDefs from '@/config/ag-grid-defs/ag-classifications.js'
import useUiStore from '@/store/modules/ui'

const uiStore = useUiStore()
const gridApi = ref()

const agGridMixin = useAgGridMixin()
const documentNLPMixin = useDocumentNLPMixin()
const documentStore = useDocumentStore()
const ability = useAbility()
const classifications = useClassifications()

defineProps({
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const columnDefs = computed(() => {
  const columns = agClassificationListDefs.columnDefs

  for (let column of columns) {
    // Default column to hidden if there are no values
    const nullValues =
      classifications.filteredClassificationList.value.filter((i) => i[column.field] !== null)
        .length === 0

    if (classifications.filteredClassificationList.value.length > 0 && nullValues) {
      column.hide = true
    }

    if (column.field === 'FileNumber') {
      column.valueGetter = (params) => {
        return `${params.data.FileNumber}${params.data.ClassificationId}`
      }
      column.valueFormatter = (params) => {
        return params.data.FileNumber
      }
    }
    if (column.field === 'ConfidenceLevel') {
      column.valueGetter = (params) => {
        return documentStore.getConfidenceLevel(params.data?.Classifier)
      }
      if (!ability.can('view', 'document:confidence')) {
        column.hide = true
        column.valueGetter = null
      }
    }
    if (column.field === 'ClassificationStatusIcon') {
      column.cellClass = (params) => {
        if (params.data && params.data.ClassificationStatus === 'classified') {
          return [
            params.data
              ? `has-background-${documentNLPMixin.getClassificationStatusColor(params.data.Classifier)} has-text-${
                  documentNLPMixin.getClassificationStatusColor(params.data.Classifier) ===
                  'grey-dark'
                    ? 'white'
                    : ''
                } no-pad classification-grid-icon`
              : 'no-color no-pad classification-grid-icon',
          ]
        }
        return 'no-pad classification-grid-icon'
      }
      column.cellRenderer = (params) => {
        if (params.data) {
          if (params.data.ClassificationStatus === 'classified') {
            const icon = getNIcon({
              type: 'fal',
              icon: documentNLPMixin.getClassificationStatusIcon(params.data.Classifier),
            })

            return `<div class="activity-icon-wrapper">${icon ? icon.outerHTML : ''}</div>`
          }

          const icon = getNIcon({
            type: 'fal',
            icon: documentNLPMixin.getClassificationLabelIcon(params.data),
          })

          return `<div class="activity-icon-wrapper">${icon ? icon.outerHTML : ''}</div>`
        }
        return ''
      }
    }

    // Adds tooltip to ag-grid
    column.tooltipValueGetter = (row) => {
      // No data if rows are grouped
      if (!row.data) return

      const classifier = documentNLPMixin.getClassifier(row.data.Classifier)

      return classifier.explanation ? `[${classifier.longName}] ${classifier.explanation}` : null
    }

    if (column.field === 'Outcome') {
      column.tooltipValueGetter = (row) => {
        if (!row.data) return
        return row.data.Outcome
          ? `Latest validation outcome:  ${_.startCase(row.data.Outcome)}`
          : 'No validations found'
      }
    }
  }

  return columns
})

watch(
  documentNLPMixin.timelineSortOptions,
  (options) => {
    if (!_.isUndefined(options[0])) {
      setGridSortOptions(options)
    }
  },
  // Removed 29/06, causing grid to be selected twice
  // selectedDocument(item) {
  //   this.handleRowDblClick({ data: item })
  // },
)

watch(
  // Refreshes the ag-grid if there are any data changes from outside
  classifications.filteredClassificationList,
  () => {
    if (!gridApi.value) return
    gridApi.value.setRowData(classifications.filteredClassificationList.value)
  },
)
const setGridSortOptions = (options) => {
  if (gridApi.value) {
    gridApi.value.setSortModel(options)
  }
}

// set a unique row node id
const getRowNodeId = (row) => {
  return agGridMixin.getAgRowId('classifications', row.data)
}

const onGridReady = (params, gridName) => {
  gridApi.value = agGridMixin.onGridReady(params, gridName).api

  // Set the grid data if it's loaded before the grid is created
  gridApi.value.setRowData(classifications.filteredClassificationList.value)

  // Set the selected rows from before the grid is created
  if (documentStore.focusedFileNumber) {
    documentNLPMixin.selectFileRowNodes(gridApi.value, documentStore.focusedFileNumber)
  }
}
</script>

<style lang="less">
// Override the background color of the ag-selected-row in the document table
.classification-table {
  --ag-selected-row-background-color: #fff785 !important;
}
.classification-grid-icon {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
}
</style>
