<template>
  <ag-grid-vue
    style="width: 100%; height: 70vh"
    :modules="agGridMixin.agModules"
    class="validation-history-table"
    :class="uiStore.state.isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham'"
    :default-col-def="defaultColDef"
    :column-defs="columnDefs"
    :side-bar="agTimelineDefs.sidebar"
    :status-bar="agTimelineDefs.statusPanels"
    :tools-panel="agTimelineDefs.toolPanels"
    :row-drag="agTimelineDefs.rowDrag"
    :row-data="getContent"
    :animate-rows="true"
    :master-detail="true"
    :row-group-panel-show="'always'"
    :enable-cell-text-selection="false"
    :suppress-copy-rows-to-clipboard="true"
    row-selection="multiple"
    :pivot-panel-show="'always'"
    :detail-row-auto-height="true"
    :group-default-expanded="false"
    :detail-cell-renderer-params="detailCellRendererParams"
    :enable-range-selection="true"
    :enable-charts="true"
    :get-row-id="getRowNodeId"
    :tooltip-show-delay="tooltipOptions.showDelay"
    :tooltip-hide-delay="tooltipOptions.hideDelay"
    @row-clicked="documentNLPMixin.selectAgRows"
    @grid-ready="(params) => onGridReady(params, 'validationHistory')"
  />
</template>

<script setup>
import useValidationStore from '@/store/modules/validation'
import { getDateComparator, getFriendlyDate } from '@/mixins/n-time'
import { AgGridVue } from 'ag-grid-vue3'
import useAgGridMixin from '@/mixins/agGridMixin'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import useDocumentStore from '@/store/modules/document'
import _ from 'lodash'
import defColDef from '@/mixins/globalDefaultColDef'
import { computed, defineProps, ref } from 'vue'
import agTimelineDefs from '@/config/ag-grid-defs/ag-activities.js'
import useUiStore from '@/store/modules/ui'

const uiStore = useUiStore()
const agGridMixin = useAgGridMixin()
const validationStore = useValidationStore()
const documentStore = useDocumentStore()
const documentNLPMixin = useDocumentNLPMixin()
const gridApi = ref()

defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: true,
  },
})

const tooltipOptions = {
  showDelay: 0,
  hideDelay: 200,
}

const defaultColDef = {
  ...defColDef,
  tooltipComponent: 'agTooltipComponent',
}
const detailCellRendererParams = {
  detailGridOptions: {
    detailRowHeight: 300,
    columnDefs: [
      {
        headerName: 'Date',
        field: 'ValidatedOn',
        maxWidth: 220,
        filter: 'agSetColumnFilter',
        resizable: true,
        cellRenderer: (params) => {
          if (params.data?.ValidatedOn) {
            return getFriendlyDate(params.data.ValidatedOn)
          }
        },
      },
      {
        headerName: 'Outcome',
        field: 'ValidationCategory',
        filter: 'agSetFilter',
        width: 300,
        resizable: true,
        cellRenderer: (params) => {
          return (
            params.data.ValidationCategory ||
            params.data.ClassifierChangedTo ||
            params.data.ValidationType
          )
        },
      },
      {
        headerName: 'Validated By',
        field: 'ValidatedBy',
        filter: 'agTextColumnFilter',
        hide: false,
        width: 150,
        resizable: true,
      },
      {
        headerName: 'Validated Comments',
        field: 'ValidationComment',
        filter: 'agTextColumnFilter',
        hide: false,
        width: 150,
        resizable: true,
        cellRenderer: (params) => {
          // Replace HTML as master detail doesn't support HTML rendering
          return params.data.ValidationComment.replace(/<[^>]*>?/gm, '')
        },
      },
    ],
  },
  getDetailRowData: (params) => {
    if (params.data.ClassificationId) {
      const classifierHistory = validationStore.filterClassificationHistory(
        params.data.ClassificationId,
      )
      return params.successCallback(classifierHistory)
    }
    if (params.data.ValidationTarget) {
      const pathwayValidationHistory = validationStore.pathwayValidationHistory
      return params.successCallback(pathwayValidationHistory)
    }
  },
}

const columnDefs = ref([
  {
    headerName: '',
    field: 'actions',
    width: 20,
    maxWidth: 30,
    filter: false,
    suppressSizeToFit: true,
    suppressMenu: true,
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    headerName: 'Date',
    field: 'ValidatedOn',
    tooltipField: 'ValidatedOn',
    filter: 'agDateColumnFilter',
    sort: 'desc',
    sortingOrder: ['desc', 'asc'],
    width: 120,
    suppressSizeToFit: true,
    resizable: true,
    filterParams: {
      comparator: getDateComparator,
    },
    cellRenderer: (params) => {
      if (params.data?.ValidatedOn) {
        return getFriendlyDate(params.data.ValidatedOn)
      }
    },
  },
  {
    headerName: 'Type',
    field: 'ValidationTarget',
    filter: 'agSetFilter',
    tooltipField: 'ValidationTarget',
    resizable: true,
    filterParams: { selectAllOnMiniFilter: true },
    hide: false,
    valueGetter: (data) => {
      // Get classification from value, if it doesn't exist then return the ValidationTarget
      const classification = documentStore.classificationList.find(
        (c) => c.ClassificationId === data.data?.ClassificationId,
      )
      if (data.data?.ValidationTarget === 'pathway' || !classification)
        return _.capitalize(data.data?.ValidationTarget)

      return `${_.capitalize(data.data?.ValidationTarget)}: ${classification.ClassifierLabel}`
    },
  },
  {
    headerName: 'Outcome',
    field: 'ValidationType',
    filter: 'agSetFilter',
    tooltipField: 'ValidationType',
    resizable: true,
    filterParams: { selectAllOnMiniFilter: true },
    hide: false,
    valueGetter: (data) => {
      return _.capitalize(
        data.data?.ClassifierChangedTo ||
          data.data?.ValidationType ||
          data.data?.ValidationCategory,
      )
    },
  },
  {
    headerName: 'Validated By',
    field: 'ValidatedBy',
    filter: 'agSetFilter',
    tooltipField: 'ValidatedBy',
    resizable: true,
    filterParams: { selectAllOnMiniFilter: true },
    hide: false,
  },
  {
    headerName: 'Validation Comments',
    field: 'ValidationComment',
    filter: 'agTextColumnFilter',
    tooltipField: 'ValidationComment',
    resizable: true,
    filterParams: { selectAllOnMiniFilter: true },
    hide: false,
  },
])

const getContent = computed(() => {
  const validations = validationStore.validationHistory.map((h) => ({
    ...h,
    actions: '',
  }))
  return validations
})

const onGridReady = (params, gridName) => {
  gridApi.value = agGridMixin.onGridReady(params, gridName).api

  // Set the selected rows from before the grid is created
  documentNLPMixin.selectFileRowNodes(
    gridApi.value,
    documentNLPMixin.documentStore.focusedFileNumber,
  )
}

const getRowNodeId = (row) => {
  return agGridMixin.getAgRowId('validationHistory', row.data)
}
</script>

<style lang="less">
// Override the background color of the ag-selected-row in the document table
.validation-history-table {
  --ag-selected-row-background-color: #fff785 !important;
}
</style>
