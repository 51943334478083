import { defineStore } from 'pinia'
import ls from '@/helpers/localStorage'
import useTimelineStore from '@/store/modules/timeline'
import timelineOptions from '@/config/timeline/vis-timeline-options.js'

import _ from 'lodash'
import { ref, reactive } from 'vue'

export default defineStore('ui', () => {
  const modalApis = {
    patientMasterRecords: null,
  }
  const state = reactive({
    visTimelineGroupMode: 'none',
    visTimelineOrderMode: 'none',
    visShowActivities: true,
    visShowRTT: true,
    visShowLocalRTTCodes: true,
    visShowValidations: true,
    visShowRova: true,
    visShowColour: true,
    visShowIllogicalColours: true,
    timelineSortBy: 'desc',
    eventViewerDefaultTab: 'records', // Card is descoped and is changed from the default tab
    eventInspectorDefaultTab: 'detail',
    tooltipsEnabled: true,
    copyTagLabel: false,
    isFullscreen: false,
    hasPatientBar: true,
    isOffCanvasOpen: false,
    loaderCount: 0,
    error: null,
    innerHeight: null,
    patientMasterRecordsVisible: false,
    visHiddenGroups: [],
    visZoomMonths: 0,
    visZoomLast: true,
    visRangeOffset: 0,
    visOffsetClicked: 0,
    probeSwitches: {
      probe: true,
      regex: true,
      rova: true,
      doc: true,
    },
    probeHighlighting: 'rova',
    visShowDetail: true,
    visSelectedTimelineOptions: [],
    isDark: false,
  })

  const visCurrentStart = ref()
  const visCurrentEnd = ref()

  const setDefaultField = ({ field, value }) => {
    state[field] = value
    ls.set('default-values', state)
  }

  const loadDefaultValues = () => {
    const applicationConfig = _.get(ls.get('config'), 'application-config.defaultValues')

    // Loop and update any valid fields
    if (typeof applicationConfig === 'object') {
      for (const field in applicationConfig) {
        if (state[field] !== null && state[field] !== undefined) {
          state[field] = applicationConfig[field]
        }
      }
    }

    // Check if the user has any defaults
    const userDefaults = ls.get('default-values')

    // Loop and update any valid fields
    if (typeof userDefaults === 'object') {
      for (const field in userDefaults) {
        if (state[field] !== null && state[field] !== undefined) {
          state[field] = userDefaults[field]
        }
      }
    }

    // Set visSelectedTimelineOptions to all options if nothing is selected in local storage
    if (state.visSelectedTimelineOptions.length === 0) {
      state.visSelectedTimelineOptions = timelineOptions.visTimelineOptions.map(
        (option) => option.value,
      )
    }

    // Update dependent stores
    const timelineStore = useTimelineStore()
    timelineStore.updateDefaultValues(state.timelineSortBy)
  }

  const loaderCount = ref(0)

  const loaderStart = () => {
    loaderCount.value += 1
  }
  const loaderFinish = () => {
    setTimeout(() => {
      loaderCount.value -= 1
    }, 100)
  }

  const updateUserDefaults = (value, field) => {
    setDefaultField({ field, value })
  }

  return {
    state,
    loaderCount,
    loaderStart,
    loaderFinish,
    updateUserDefaults,
    setDefaultField,
    loadDefaultValues,
    visCurrentStart,
    visCurrentEnd,
    modalApis,
  }
})
