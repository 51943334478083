<template>
  <ag-grid-vue
    data-cy="documents-grid"
    style="width: 100%; height: 70vh"
    :modules="agGridMixin.agModules"
    class="document-table"
    :class="uiStore.state.isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham'"
    :column-defs="columnDefs"
    :default-col-def="defaultColDef"
    :side-bar="agTimelineDefs.sidebar"
    :status-bar="agTimelineDefs.statusPanels"
    :tools-panel="agTimelineDefs.toolPanels"
    :row-drag="agTimelineDefs.rowDrag"
    :animate-rows="true"
    :suppress-no-rows-overlay="true"
    :row-group-panel-show="'always'"
    :enable-cell-text-selection="false"
    :enable-browser-tooltips="true"
    :suppress-copy-rows-to-clipboard="true"
    :suppress-row-click-selection="true"
    row-selection="multiple"
    :pivot-panel-show="'always'"
    :detail-row-auto-height="true"
    :group-default-expanded="false"
    :master-detail="true"
    :detail-cell-renderer-params="detailCellRendererParams"
    :get-row-id="getRowNodeId"
    @row-clicked="documentNLPMixin.selectAgRows"
    @row-selected="onRowSelected"
    @grid-ready="(params) => onGridReady(params, 'documents')"
  />
</template>

<script setup>
import { AgGridVue } from 'ag-grid-vue3'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import useAgGridMixin from '@/mixins/agGridMixin.js'
import { chain, first } from 'lodash'
import { getNIcon } from '@/helpers/component-loader'
import defaultColDef from '@/mixins/globalDefaultColDef'
import { watch, defineExpose, ref, toRef } from 'vue'
import useDocumentStore from '@/store/modules/document'
import agTimelineDefs from '@/config/ag-grid-defs/ag-activities.js'
import agDocumentsListDefs from '@/config/ag-grid-defs/ag-documents.js'
import useClassifications from '@/mixins/classification'
import useUiStore from '@/store/modules/ui'

const uiStore = useUiStore()
const gridApi = ref()
const classifications = useClassifications()
const agGridMixin = useAgGridMixin()
const documentNLPMixin = useDocumentNLPMixin()
const documentStore = useDocumentStore()

const props = defineProps({
  data: {
    type: [Array, Object],
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
    default: true,
  },
})

const columnDefs = agDocumentsListDefs

const detailCellRendererParams = {
  detailGridOptions: {
    maxHeight: 300,
    columnDefs: [
      {
        headerName: '',
        field: 'ClassificationStatusIcon',
        filter: false,
        resizable: false,
        width: 30,
        maxWidth: 30,
        cellClass: (params) => {
          if (params.data && params.data.ClassificationStatus === 'classified') {
            return [
              params.data
                ? `has-background-${documentNLPMixin.getClassificationStatusColor(params.data.Classifier)} has-text-${
                    documentNLPMixin.getClassificationStatusColor(params.data.Classifier) ===
                    'grey-dark'
                      ? 'white'
                      : ''
                  } no-pad documents-grid-icon`
                : 'no-color no-pad documents-grid-icon',
            ]
          }
          return ''
        },
        cellRenderer: (params) => {
          if (params.data) {
            if (params.data.ClassificationStatus === 'classified') {
              const icon = getNIcon({
                type: 'fal',
                icon: documentNLPMixin.getClassificationStatusIcon(params.data.Classifier),
              })

              return `<div class="activity-icon-wrapper">${icon ? icon.outerHTML : ''}</div>`
            }

            const icon = getNIcon({
              type: 'fal',
              icon: documentNLPMixin.getClassificationLabelIcon(params.data),
            })

            return `<div class="activity-icon-wrapper">${icon ? icon.outerHTML : ''}</div>`
          }
        },
      },
      {
        headerName: 'Classifier',
        field: 'ClassifierLabel',
        maxWidth: 220,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Sentence',
        field: 'Sentence',
        filter: 'agTextColumnFilter',
        width: 300,
        resizable: true,
      },
      {
        headerName: 'Phrase',
        field: 'Phrase',
        filter: 'agTextColumnFilter',
        hide: false,
        width: 150,
        resizable: true,
      },
    ],
    defaultColDef: {
      flex: 1,
    },
  },
  getDetailRowData: (params) => {
    params.successCallback(params.data.children)
  },
}

const gridData = toRef(props, 'data')

watch(
  // Refreshes the ag-grid if there are any data changes from outside
  classifications.filteredClassificationList,
  () => {
    if (!gridApi.value) return
    gridApi.value.setRowData(groupData(gridData))
    syncSelectedRows()
  },
)

const syncSelectedRows = () => {
  const nodesToSelect = []
  gridApi.value.forEachNode((node) => {
    if (documentStore.selectedFileNumbers.includes(node.data.FileNumber)) {
      nodesToSelect.push(node)
    }
  })

  gridApi.value.setNodesSelected({ nodes: nodesToSelect, newValue: true })
}

const groupData = () => {
  return chain(gridData.value)
    .groupBy('FileId')
    .map((value) => {
      const baseChild = first(value)

      return {
        selected: '',
        ...baseChild,
        children: value,
      }
    })
    .value()
}

const onRowSelected = (row) => {
  if (row.source === 'checkboxSelected') {
    updateDocumentSelection()

    // Select classifications & document when selection is turned back on.
    if (row.data.FileNumber === documentStore.focusedFileNumber) {
      documentNLPMixin.selectAgRows(row)
    }
  }
}

const getSelections = () => {
  const data = gridApi.value.getSelectedNodes()

  return data.map((elm) => {
    return elm.data
  })
}

const updateDocumentSelection = async () => {
  const data = getSelections()

  const FileNumbers = data.map((elm) => elm.FileNumber)

  documentStore.selectedFileNumbers = FileNumbers
  documentStore.updateClassifications()
}

const setFocusedRows = () => {
  documentNLPMixin.focusRowNode(gridApi.value, documentStore.focusedFileNumber)
}

const onGridReady = (params, gridName) => {
  gridApi.value = agGridMixin.onGridReady(params, gridName).api

  // Set the selected rows for document ids on load
  gridApi.value.setRowData(groupData(gridData))
  syncSelectedRows()

  // Set the focused rows from before the grid is created
  setFocusedRows()
}

// set a unique row node id
const getRowNodeId = (row) => {
  return agGridMixin.getAgRowId('documents', row.data)
}

defineExpose({ getSelections })
</script>

<style lang="less">
// Set the background color of the ag-row-focus in the document table
html:not(.is-dark) .document-table .ag-row-focus::before {
  --ag-selected-row-background-color: #fff785 !important;
}

// Override the background color of the ag-selected-row in the document table
html:not(.is-dark) .document-table {
  --ag-selected-row-background-color: #ffffff !important;
}

.documents-grid-icon {
  display: flex;
  justify-content: center;
  align-content: center;
  & .svg-inline--fa {
    margin-bottom: 1px;
  }
}
</style>
