import './plugins/vue-ag-grid'
import { i18n } from './plugins/i18n'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import initSentry from './plugins/sentry'
import getAuthToken from './helpers/aad'
import { mergeConfig } from './config'
import axios from 'axios'
import { apiUrl } from './host-config'
import App from './App.vue'
import router from './router'
import VueTippy from 'vue-tippy'
import 'tippy.js/themes/light.css'
import Clipboard from 'v-clipboard'

import Antd from 'ant-design-vue'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
// Style Sheets
import 'bulma/css/bulma.min.css'
import './styles/n-ui/n-colors.less'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import './assets/base.css'
import './assets/style.js'

const app = createApp(App)

app
  .use(VueTippy, { component: 'tippy-component' })
  .use(i18n)
  .use(createPinia())
  .use(router)
  .use(Clipboard)
  .use(Antd)
  .use(VueMonacoEditorPlugin, {
    paths: {
      // The recommended CDN config
      vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs',
    },
  })

// TODO Get api url from user config
const urlParams = new URLSearchParams(window.location.search)
const surveyorApiUrl = urlParams.get('surveyorApiUrl')
const embedded = urlParams.get('embedded')

// Store the surveyorApiUrl in the session storage to avoid azure ad redirect stripping it
if (surveyorApiUrl) {
  sessionStorage.setItem('surveyor-api-url', surveyorApiUrl)
}

// Get hostname from supplied API Url or window.location
const suppliedApiUrl = surveyorApiUrl || sessionStorage.getItem('surveyor-api-url')
const host = suppliedApiUrl ? new URL(suppliedApiUrl).hostname : window.location.hostname

const sessionApiUrl = suppliedApiUrl || apiUrl

axios
  .get(`${sessionApiUrl}config/app/${host}`, { withCredentials: true })
  .then(async (response) => {
    // Set global config from api response
    const hostSpecificConfig = response.data
    hostSpecificConfig.apiUrl = sessionApiUrl

    // Force azure auth mode if api url is supplied, required for loading standalone surveyor with aad api
    if (suppliedApiUrl) {
      hostSpecificConfig.authMode = 'azure'
    }

    // Merge host specific config with default config
    mergeConfig(hostSpecificConfig)

    // Get authentication token before first request to avoid redirect loop
    const options = {}

    // Embedded mode should not redirect or reload on new token
    if (embedded === 'true') {
      options.acquireTokenRedirect = false
      options.reloadOnNewToken = false
    }

    await getAuthToken(options)

    // Intialize plugins that require config from api
    initSentry()

    // Document domain for forms
    // document.domain = hostSpecificConfig.browser.domain.split(':')[0]

    // Mount vue app
    app.mount('#app')
  })
