<template>
  <ag-grid-vue
    data-cy="tags-grid"
    style="width: 100%; height: 70vh"
    :modules="agGridMixin.agModules"
    :class="uiStore.state.isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham'"
    :default-col-def="defaultColDef"
    :column-defs="columnDefs"
    :row-data="getContent"
    :animate-rows="true"
    :master-detail="true"
    :floating-filter="true"
    :row-group-panel-show="'always'"
    :enable-cell-text-selection="false"
    :enable-browser-tooltips="true"
    :suppress-copy-rows-to-clipboard="true"
    :suppress-row-click-selection="true"
    :pivot-panel-show="'always'"
    :enable-range-selection="true"
    :enable-charts="true"
    @grid-ready="(params) => agGridMixin.onGridReady(params, 'tags')"
  />
</template>

<script setup>
import { AgGridVue } from 'ag-grid-vue3'
import defaultColDef from '@/mixins/globalDefaultColDef'
import { computed } from 'vue'
import useDocumentStore from '@/store/modules/document'
import useAgGridMixin from '@/mixins/agGridMixin.js'
import useUiStore from '@/store/modules/ui'

const uiStore = useUiStore()
const agGridMixin = useAgGridMixin()
const documentStore = useDocumentStore()

const columnDefs = [
  {
    headerName: 'Tag',
    field: 'tag',
    width: 200,
    filter: 'agTextColumnFilter',
    filterParams: { selectAllOnMiniFilter: true },
    hide: false,
  },
  {
    headerName: 'Text',
    field: 'text',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Normalised',
    field: 'normal',
    filter: 'agTextColumnFilter',
  },
]

const getContent = computed(() => {
  const content = []

  for (let item of documentStore.documentModels) {
    for (let tag of item.tags) {
      content.push(tag)
    }
  }

  return content
})
</script>
