<template>
  <span v-if="value" @click.stop.prevent="copySuccessMessage()">
    <div class="n-tag">
      <div class="n-tag--wrapper">
        <a-tag
          v-clipboard="() => getCopyTag(`${label}: ${value}`)"
          :class="{ 'blurry-text': blurred }"
        >
          <n-icon v-if="icon" :icon="['fal', icon]" />
          {{ label }}
          <strong :class="{ 'blurry-text': blurred, [props.valueStyle]: props.valueStyle }">{{
            value
          }}</strong>
        </a-tag>
        <slot />
      </div>
    </div>
  </span>
</template>
<script setup>
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import { notification } from 'ant-design-vue'
import useUIStore from '@/store/modules/ui'
import useAbility from '@/services/ability'
import { defineProps } from 'vue'

// name: 'NTag',
const props = defineProps({
  icon: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: String,
    required: false,
    default: '',
  },
  valueStyle: {
    type: String,
    required: false,
    default: '',
  },
  blurred: {
    type: Boolean,
    required: false,
    default: false,
  },
  clickToCopy: {
    type: Boolean,
    required: false,
    default: true,
  },
})
const uiStore = useUIStore()
const ability = useAbility()

const getCopyTag = (tag) => {
  if (ability.can('copy', 'tag') && props.clickToCopy) {
    if (uiStore.state.copyTagLabel) {
      return tag
    }
    return tag.split(':')[1].trim()
  }
}

const copySuccessMessage = () => {
  if (ability.can('copy', 'tag') && props.clickToCopy) {
    notification.success({
      message: 'Tag contents copied to clipboard',
    })
  }
}
</script>
<style scoped lang="less">
// @import '../../../styles/variables';

.blurry-text {
  text-shadow: 0 0 8px black;
  color: transparent !important;
}
.is-dark .blurry-text {
  text-shadow: 0 0 8px white;
  color: transparent !important;
}
.n-tag {
  white-space: nowrap;
  display: inline-block;
  font-weight: 400 !important;
}
.ant-tag {
  background: #fafafa;
}
.n-tag .link {
  text-decoration: underline;
  color: blue;
}
</style>
