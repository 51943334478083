<template>
  <div v-if="ability.can('view', 'activity:demo-scenarios')" class="tl-filters" ref="dropdown">
    <div class="n-txt--right">
      <n-tooltip :text="$t('Patient Pathways')" placement="top">
        <a-button
          data-cy="patient-pathways-btn"
          class="n-m-0 n-ml-1"
          style="top: -2px"
          @click.prevent="ddVisible = !ddVisible"
        >
          <n-icon :icon="['fal', 'caret-down']" size="lg" class="n-m-0" />
        </a-button>
      </n-tooltip>
    </div>
    <div v-if="ddVisible" class="tl-filters__dd" :class="{ 'dd-show': ddVisible }">
      <ag-grid-vue
        :modules="agGridMixin.agModules"
        style="width: 100%; height: 400px"
        class="patient-dropdown"
        :class="uiStore.state.isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham'"
        :column-defs="columnDefs"
        :row-data="rowData"
        :grid-options="options"
        @row-clicked="handleRowSelection"
      />
    </div>
  </div>
</template>

<script setup>
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import { activityService } from '@/services/endpoints/activity'
import NTooltip from '@/components/ui/n-tooltips/n-tooltip.vue'
import useTimelineMixin from '@/mixins/timeline.mixin'
import useAgGridMixin from '@/mixins/agGridMixin'
import useTimelineStore from '@/store/modules/timeline'
import { onMounted, ref } from 'vue'
import useAbility from '@/services/ability.js'
import { onClickOutside } from '@vueuse/core'
import { AgGridVue } from 'ag-grid-vue3'
import useUiStore from '@/store/modules/ui'

const uiStore = useUiStore()
// @todo add translations for column headers

const agGridMixin = useAgGridMixin()
const timelineMixin = useTimelineMixin()
const timelineStore = useTimelineStore()

const dropdown = ref(null)

const ability = useAbility()
const ddVisible = ref(false)

const options = {
  rowSelection: 'single',
  animateRows: false,
  rowGroupPanelShow: 'never',
}

const columnDefs = [
  {
    headerName: 'Desc',
    field: 'Scenario',
    width: 150,
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    suppressMenu: true,
  },
  {
    headerName: 'Patient',
    field: 'PatientOID',
    width: 200,
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    suppressMenu: true,
  },
  {
    headerName: 'Pathway',
    field: 'PathwayID',
    flex: true, // stretches width to fit
    filter: 'agTextColumnFilter',
    suppressMenu: true,
  },
]
const rowData = ref([])

onMounted(() => {
  fetchRows()
})

const fetchRows = () => {
  if (ability.can('view', 'activity:demo-scenarios')) {
    activityService.getScenarioData().then((resp) => {
      rowData.value = resp
    })
  }
}

const handleRowSelection = (item) => {
  if (item.data.PTLUniqueID) {
    timelineStore.fetchPatient([[item.data.PTLUniqueID]])
    timelineMixin.setPTLUniqueId([[item.data.PTLUniqueID]])
  }
}

const closeDropdownOnOutClick = () => {
  ddVisible.value = false
}

onClickOutside(dropdown, closeDropdownOnOutClick)
</script>

<style lang="less">
.tl-filters {
  z-index: 10000;
  display: block;
  &__dd {
    right: 0;
    z-index: 120;
    visibility: hidden;
    position: absolute;
    width: 530px;
  }
}
.dd-show {
  visibility: visible;
}
.patient-dropdown {
  --ag-selected-row-background-color: #fff785 !important;
}
</style>
