<template>
  <div>
    <ag-grid-vue
      data-cy="inspector-grid"
      style="width: 100%; height: 70vh"
      :modules="agGridMixin.agModules"
      :class="uiStore.state.isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham'"
      :default-col-def="defaultColDef"
      :column-defs="columnDefs"
      :suppress-row-click-selection="true"
      :row-data="parsedItems"
      :grid-options="options"
      @grid-ready="(params) => agGridMixin.onGridReady(params, 'detail')"
    />
  </div>
</template>

<script setup>
import _ from 'lodash'
import { AgGridVue } from 'ag-grid-vue3'
import useAgGridMixin from '@/mixins/agGridMixin'
import defaultColDef from '@/mixins/globalDefaultColDef'
import { computed } from 'vue'
import useActivityStore from '@/store/modules/activity'
import useUiStore from '@/store/modules/ui'

const uiStore = useUiStore()
const agGridMixin = useAgGridMixin()
const activityStore = useActivityStore()

const options = {
  rowSelection: 'multiple',
  animateRows: true,
  rowGroupPanelShow: 'never',
  floatingFilter: true,
  localeText: { noRowsToShow: 'Highlight a Patient Activity to Show Activity Detail Here' },
}

const columnDefs = [
  {
    headerName: 'Property',
    field: 'property',
    maxWidth: 180,
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    suppressMenu: true,
  },
  {
    headerName: 'Value',
    field: 'value',
    resizable: true,
    flex: true, // stretches width to fit
    filter: 'agTextColumnFilter',
    suppressMenu: true,
  },
]

const parsedItems = computed(() => {
  // if (_.isEmpty(props.data)) return parsedItems.value
  return (
    // reformat data to key-value pairs
    _.map(activityStore.details, (value, index) => {
      return {
        property: index,
        value: value,
      }
    })
      // remove unwanted properties
      .filter((d) => !['TextContent', 'uuid', 'extn', 'created', 'updated'].includes(d.property))
  )
})
</script>
